import axios from 'axios';
import localforage from 'localforage';
import { getCodeChallenge, getCodeVerifier, getFormUrlEncodedParams, getJWTDecoded } from 'src/pkce-oidc/utils';

const CONTROL_TOWER_API_URI = '/api';
const FLYBITS_CORE_URI: { [key: string]: string } = {
  'infra-gcp': 'https://api.infra-gcp.flybits.com',
  'mc-bh': 'https://api.mc-bh.flybits.com',
  'mc-br': 'https://api.mc-br.flybits.com',
  'mc-sg': 'https://api.mc-sg.flybits.com',
  'mc-us1': 'https://api.mc-us1.flybits.com',
  'v3-europe': 'https://v3-europe.flybits.com',
  canada: 'https://v3.canada.flybits.com',
  demo: 'https://api.demo.flybits.com',
  development: 'https://api.development.flybits.com',
  diebold: 'https://api.diebold.flybits.com',
  infra: 'https://api.infra.flybits.com',
  performance: 'https://api.performance.flybits.com',
  staging: 'https://api.staging.flybits.com',
  td: 'https://v3.flybits.com',
  us: 'https://api.us.flybits.com',
  pci: 'https://api.pci.flybits.com',
  tdunmndb289: 'https://api.tdunmndb289.flybits.com',
};

export function generateAuthCode(projectId: string, clientId: string, goToUriAfterAuth: string, deployment: string) {
  const codeVerifier = getCodeVerifier();
  const codeChallenge = getCodeChallenge(codeVerifier);
  const origin = window.location.origin;
  const authRedirectUri = `${origin}/app-auth-callback/${projectId}`;

  const params = {
    scope: 'openid',
    response_type: 'code',
    code_challenge_method: 'S256',
    client_id: clientId,
    code_challenge: codeChallenge,
    redirect_uri: authRedirectUri,
    state: `${codeVerifier}+${clientId}+${authRedirectUri}+${goToUriAfterAuth}+${deployment}`,
  };

  const authRequestUrl = new URL(`${origin}/auth/request`);
  Object.entries(params).forEach((param) => {
    authRequestUrl.searchParams.append(param[0], param[1]);
  });

  window.open(authRequestUrl.href, '_blank');
}

export function generateIdToken(clientId: string, codeVerifier: string, authCode: string, authRedirectUri: string) {
  const tokenParams = {
    grant_type: 'authorization_code',
    client_id: clientId,
    code_verifier: codeVerifier,
    code: authCode,
    redirect_uri: authRedirectUri,
  };

  return axios.post(`${CONTROL_TOWER_API_URI}/auth/token`, getFormUrlEncodedParams(tokenParams), {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: '0',
    },
  });
}

export function oidcLogin(idToken: string, deployment: string) {
  const { aud } = getJWTDecoded(idToken);

  const payload = {
    projectId: aud,
    idToken,
    provider: 'flybits',
  };

  return axios.post(`${FLYBITS_CORE_URI[deployment]}/sso/auth/oidc`, payload, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: '0',
    },
  });
}

export function getUserData(accessToken: string, deployment: string) {
  return axios.get(`${FLYBITS_CORE_URI[deployment]}/sso/auth/me`, {
    headers: {
      Accept: 'application/json',
      'X-Authorization': accessToken,
    },
  });
}

export async function getValidAccessToken(projectId: string, deployment: string) {
  const accessToken: string = (await localforage.getItem(`token+${projectId}`)) || '';

  try {
    if (accessToken) {
      await getUserData(accessToken, deployment);

      return accessToken;
    } else {
      await localforage.removeItem(`token+${projectId}`);
      throw new Error('No access token found');
    }
  } catch (error) {
    await localforage.removeItem(`token+${projectId}`);
    console.error('Access token not valid ', error);

    return;
  }
}
