import localforage from 'localforage';

export async function clearDB(): Promise<string> {
  await deleteUserConfirmed();
  await localforage.clear();
  return 'success';
}

export async function setStoredEmail(email: string): Promise<string> {
  await localforage.setItem('email', email);
  return 'success';
}

export async function deleteStoredEmail(): Promise<string> {
  await localforage.removeItem(`email`);
  return 'success';
}
export async function setUserRoles(roles: string[]): Promise<string> {
  await localforage.setItem('roles', roles);
  return 'success';
}

export async function getUserRoles(): Promise<string[]> {
  const roles: string[] = (await localforage.getItem('roles')) || ['member'];
  return roles;
}
export async function deleteUserRoles(): Promise<string> {
  await localforage.removeItem(`roles`);
  return 'success';
}

export async function getStoredEmail(): Promise<string> {
  const email: string = (await localforage.getItem('email')) || '';
  return email;
}
// keeping just for backwards compatibility
export async function deleteStoredPassword(): Promise<string> {
  await localforage.removeItem(`password`);
  return 'success';
}

export async function getStoredPassword(): Promise<string> {
  const password: string = (await localforage.getItem('password')) || '';
  return password;
}

export async function setStoredProjectId(projectId: string): Promise<string> {
  await localforage.setItem('projectId', projectId);
  return 'success';
}

export async function getStoredProjectId(): Promise<string> {
  const projectId: string = (await localforage.getItem('projectId')) || '';
  return projectId;
}

export async function deleteStoredProjectId(): Promise<string> {
  await localforage.removeItem(`projectId`);
  return 'success';
}

export async function setStoredOrganizationId(organizationId: string): Promise<string> {
  await localforage.setItem('organizationId', btoa(organizationId));
  return 'success';
}

export async function getStoredOrganizationId(): Promise<string> {
  const organizationId: string = (await localforage.getItem('organizationId')) || '';
  return atob(organizationId);
}

export async function deleteStoredOrganizationId(): Promise<string> {
  await localforage.removeItem(`organizationId`);
  return 'success';
}

export async function setUserConfirmed(userEmail: string): Promise<string> {
  await localforage.setItem('userConfirmed', btoa(userEmail));
  return 'success';
}

export async function getUserConfirmed(): Promise<string> {
  const userConfirmed: string = (await localforage.getItem('userConfirmed')) || '';
  return userConfirmed;
}

export async function deleteUserConfirmed(): Promise<string> {
  await localforage.removeItem(`userConfirmed`);
  return 'success';
}

export function validateEmail(email: string): boolean {
  return /^\w+([\+.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email); //eslint-disable-line no-useless-escape
}

export function validateName(name: string): boolean {
  return /^[a-zA-Z]+$/.test(name);
}

export function validateURL(url: string): boolean {
  return urlRegex.test(url);
}

export function validateComplexName(name: string, regex?: any): boolean {
  return regex ? regex.test(name) : projectNameRegex.test(name);
}

export function validatePassword(password: string): boolean {
  return /^(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=]).*$/.test(password);
}

export const passwordRegex =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\!\@\#\$\%\^\&\*\(\\\)\-_\=\+\,\.\?\/\:\;\{\}\[\]~])[A-Za-z\d\!\@\#\$\%\^\&\*\(\\\)\-_\=\+\,\.\?\/\:\;\{\}\[\]~]{10,}/;

// export const nameRegex = /^[a-zA-Z]+$/;
export const nameRegex = /^(?![\s.]+$)[a-zA-Z-\s.]+$/;

export const projectNameRegex = /^(?![\s.]+$)[a-zA-Z0-9-\s.]+$/;

// attributes
export const beginsWithNumberOrLetter = /^[A-Z0-9]/i;
export const containsNoSpecialCharacters = /^[-A-Z0-9]*$/i;
export const paramNameRegex = /^[^. _]*$/;

export const integrationNameRegex = /^[a-zA-Z0-9- _,?!'.()]*$/;

export const deploymentRegex = /^([a-zA-Z0-9.-_,]|[- @.#&!\(\)\[\]])*$/;

export const organizationRegex = /^([a-zA-Z0-9.-_,]|[- @.#&!])*$/;

// export const urlRegex = /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;
export const urlRegex = /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;

export const unicodeEmailRegex =
  /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
